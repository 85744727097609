<template>
  <v-container fluid>
    <TableViewComponent
      title="Table ticketing > statuts par défaut"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un statut par défaut"
      itemLabel="statut par défaut"
      itemsLabel="statuts par défaut"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce statut par défaut ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";
import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableTicketingStatus",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /** la liste des applications */
      apps: [],

      /**le service d'accès */
      service: null,
      /** Le service des applications ticketing */
      serviceAppTicketing: null,

    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() { 
      this.$router.push(routes.ticketingStatus.add.path);
    },

    async load() {
      try {
        this.loading = true;

        this.entities = [];

        let entities = await this.service.getAll();

        // Récupération de toutes les apps ticketing
        this.apps = await this.serviceAppTicketing.getAllApps();

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));
          entity.view = () => {
            this.$router.push(routes.ticketingStatus.view.root + "/" + entity.id);
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(routes.ticketingStatus.edit.root + "/" + entity.id);
          };

          entity.delete = async () => {
            try {
              
              // Parcours de l'ensemble des apps ticketing
              for (let app of this.apps) {
                // Récupération des statuts d'une app
                let statusApp = await this.serviceAppTicketing.getAllStatusOfApplication(app);

                // L'application a-t-elle le status ?
                let found = statusApp.find((s) => s.id == entity.id);
                
                // Si l'application a le statut --> le désassocie
                if (found) {
                  await this.serviceAppTicketing.disassociateStatusOfApp(app, entity.id);
                }
              }
              
              // Suppression du statut par défaut
              await this.service.delete(entity.id);

              this.load();

            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du statut : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

        // Tri des statuts par défaut par "ordre"
        this.entities.sort(function(a, b) {
          return a.ranking - b.ranking;
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: false,
          value: "label",
        },
        {
          text: "Ordre",
          align: "start",
          sortable: true,
          value: "ranking",
        },
        {
          text: "Clé technique",
          align: "start",
          sortable: true,
          value: "tid",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },

    /** Analyse si l'application a les status par défaut ou non */
    checkAppHasDefaultStatus(statusApp) {
      let statutDefault = true;

      // Parcours la liste des status de l'application 
      for (let status of statusApp) {
        // Tente de trouver le statut par défaut correspondant
        let found = this.entities.find((d) => d.id == status.id);

        if (!found) {
          statutDefault = false;
          break;
        }
      }

      return statutDefault;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditConf];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditConf, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadConf];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new TicketingStatusService(this.$api.getTicketingStatus());
    this.serviceAppTicketing = new TicketingObjectSubobjectService(
      this.$api.getTicketingObjectSubobject()
    );


    this.load();
  },
  
};
</script>

<style>
</style>